
import { defineComponent } from 'vue'
import { openOutline } from 'ionicons/icons'
import { useAppInsights } from '@dview/shared/util'

export default defineComponent({
    setup() {
        const { trackEvent } = useAppInsights()
        const feedbackUrl = 'https://teams.microsoft.com/l/channel/19%3a212848dd602e4984924a1856446bf1df%40thread.tacv2/Request%2520for%2520change?groupId=16bf648d-0bee-4f0b-894f-09f3a3ffbb64&tenantId=fdfed7bd-9f6a-44a1-b694-6e39c468c150'
        const supportUrl = 'https://teams.microsoft.com/l/channel/19%3a3508a7792c9043d78edfeac26e367fc3%40thread.tacv2/General?groupId=16bf648d-0bee-4f0b-894f-09f3a3ffbb64&tenantId=fdfed7bd-9f6a-44a1-b694-6e39c468c150'

        function trackFeedbackEvent() {
            trackEvent({ name: 'Feedback link accessed' })
        }

        function trackSupportEvent() {
            trackEvent({ name: 'Support link accessed' })
        }

        return {
            feedbackUrl,
            supportUrl,
            openOutline,
            trackFeedbackEvent,
            trackSupportEvent,
        }
    },
})
